<template>
  <v-btn-toggle
    v-model="isCash"
    color="primary"
    mandatory
    rounded
    @change="onChange"
  >
    <v-btn :disabled="readOnly" :value="true" small>
      {{ $t("cash") }}
    </v-btn>
    <v-btn :disabled="readOnly" :value="false" small>
      {{ $t("credit") }}
    </v-btn>
  </v-btn-toggle>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";

@Component
export default class BtnCashCreditToggle extends Vue {
  @VModel({ type: Boolean, default: true }) isCash!: boolean;
  @Prop(Boolean) readOnly!: boolean;

  onChange(sValue: boolean) {
    this.$emit("change", sValue);
  }
}
</script>
